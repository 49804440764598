<template>
  <div class="service-info">
    <hr>
    <el-form ref="form" :model="formData" :rules="formRules" label-position="top">
      <el-form-item label="服務車次" prop="serviceUnit">
        <ServicesUnitSelect
          :model.sync="formData.serviceUnit"
          @change="serviceUnitChange(), (formData.totalTime = 0)"
        />
      </el-form-item>
      <el-form-item label="服務項目" prop="service">
        <UnitServicesSelect
          showAll
          :unitId="getData(formData.serviceUnit, 'id', '')"
          :model.sync="formData.service"
          @change="serviceChange"
        />
      </el-form-item>
      <el-form-item v-if="enableSubService" label="子項目" prop="subService">
        <el-select v-model="formData.subService" value-key="id" @change="subServiceChange">
          <el-option
            v-for="service in subServiceList"
            :key="service.id"
            :label="service.name"
            :value="service"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="附加服務">
        <AttachServiceSelect
          :model.sync="formData.attachServices"
          :services="formData.service"
          multiple
          @change="attachServiceChange"
        />
      </el-form-item>
      <el-form-item label="預估服務時數(分鐘)" prop="totalTime">
        <el-select
          v-model="formData.totalTime"
          placeholder="請選擇"
          @change="totalTimeChange"
        >
          <el-option
            v-for="(item, index) in genNums()"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="是否為加班時段">
        <el-radio-group
          v-model="formData.isOverTime"
          @change=";(formData.time = '')"
        >
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="預約時段" prop="time">
        <div v-if="!date || !formData.serviceUnit || !formData.service">請先選擇服務車次與服務項目</div>
        <AvailableTimeSelect
          v-if="date && formData.serviceUnit && formData.service && !formData.isOverTime"
          :model.sync="formData.time"
          :timesList="availableDate"
        />
        <!-- <ServiceUnitTimeSelect
          v-if="date && !formData.isOverTime"
          :model.sync="formData.time"
          :date="date"
          :unit="formData.serviceUnit"
          :totalTime="formData.totalTime"
        /> -->
        <el-select
          v-if="date && formData.isOverTime"
          v-model="formData.time"
          placeholder="請選擇"
          no-data-text="無數據"
        >
          <el-option
            v-for="(item, index) in genTimes()"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
// import ServiceUnitTimeSelect from '@/components/Select/ServiceUnitTimeSelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import AvailableTimeSelect from '@/components/Reservation/AvailableTimeSelect/AvailableTimeSelect.vue'
import { GetAvailableTimeByTotalBookingTime } from '@/api/serviceUnit'
import { generateTimes, generateNumbers } from '@/utils/helper'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { noEmptyRules } from '@/validation'
import { get, find, filter, isEmpty } from 'lodash'
import { isInvalidDate } from '@/utils/date'
import { FindService } from '@/api/service'

export default {
  name: 'BlockOtherServiceSelect',
  components: {
    UnitServicesSelect,
    ServicesUnitSelect,
    AttachServiceSelect,
    // ServiceUnitTimeSelect,
    AvailableTimeSelect,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    date: {
      type: Date,
    },
    peopleCount: {
      type: Number,
      default: 0,
    },
    mainServiceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      availableDate: [],

      formData: {
        otherService: false,
        service: null,
        subService: null,
        attachServices: null,
        serviceUnit: null,
        totalTime: 0,
        isOverTime: false,
        date: '',
        time: '',
      },
      formRules: {
        service: [noEmptyRules()],
        subService: [noEmptyRules()],
        serviceUnit: [noEmptyRules()],
        date: [noEmptyRules()],
        time: [noEmptyRules()],
      },

      pickerOptions: {
        disabledDate: (date) => {
          // if (this.formData.isOverTime) return false
          const d = dayjs(date).format('YYYY/MM/DD')
          return !this.availableDate.find((item) => item === d)
        },
      },
    }
  },

  computed: {
    ...mapGetters(['shop', 'shopTimeUnit']),
    totalTime () {
      const form = this.formData
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let count = 0
      if (form.attachServices !== '' && form.attachServices) {
        form.attachServices.forEach((item) => {
          count += item.bookingTime
        })
      }

      return base + count
    },
    subServiceList () {
      if (!this.formData.service) return []
      return filter(get(this.formData, 'service.AppointmentSubServices'), (s) => find(s.AppointmentUnits, { id: get(this.formData, 'serviceUnit.id') }) || isEmpty(s.AppointmentUnits))
    },
    enableSubService () {
      return get(this.formData.service, 'enableSubService')
    },
  },
  watch: {
    formData: {
      handler: async function (data) {
        if (data.service && data.serviceUnit) {
          await this.getAvailableTimeByTotalBookingTime()
        }
        return this.$emit('update', data)
      },
      deep: true,
    },
    mainServiceData: {
      handler: async function (data) {
        this.reset()
        return this.$emit('update', this.formData)
      },
      deep: true,
    },
  },
  async mounted () {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })
  },
  methods: {
    getData (obj, path, defaultValue) {
      return get(obj, path, defaultValue)
    },
    genNums () {
      return generateNumbers(0, 600, this.shopTimeUnit)
    },
    genTimes () {
      const offset = this.config.timeUnitOffset
      const timeUnit = this.config.timeUnit
      return generateTimes(timeUnit, offset)
    },

    async getAvailableTimeByTotalBookingTime () {
      const date = dayjs(this.mainServiceData.date).format('YYYY/MM/DD')
      let preTime = dayjs(this.mainServiceData.time).format('HH:mm')
      if (isInvalidDate(preTime)) preTime = this.mainServiceData.time

      const start = dayjs(`${date} ${preTime}`)
      const end = dayjs(start.endOf('d'))

      const [res, err] = await GetAvailableTimeByTotalBookingTime({
        shopId: this.shop,
        id: get(this.formData.serviceUnit, 'id', ''),
        appointmentUnitId: get(this.formData.serviceUnit, 'id', ''),
        start: start.toDate(),
        end: end.toDate(),
        volume: this.peopleCount,
        useAllAppointmentUnit: false,
        useNotSpecifyUnits: false,
        totalBookingTime: this.formData.totalTime,

        preReservation: [{
          appointmentUnitId: get(this.mainServiceData, 'serviceUnit.id', ''),
          start: start.toDate(),
          totalBookingTime: this.mainServiceData.totalTime,
        }],
      })
      if (err) return this.$message.error(err)
      this.availableDate = res.times.filter(i => dayjs(i).isSame(dayjs(this.mainServiceData.date), 'date'))
    },

    totalTimeChange () {
      if (this.formData.isOverTime) return
      this.formData.date = ''
    },

    serviceUnitChange () {
      this.formData.service = ''
      this.formData.attachServices = []
      this.formData.date = ''
      this.formData.time = ''
    },

    async serviceChange () {
      await this.findService()
      this.formData.subService = null
      this.formData.attachServices = []
      this.formData.totalTime = this.totalTime
      this.formData.date = ''
      this.formData.time = ''
    },
    subServiceChange (service) {
      this.formData.subService = service
      this.formData.totalTime = this.totalTime
      this.formData.attachServices = []
      this.formData.date = ''
      this.formData.time = ''
    },

    attachServiceChange () {
      this.formData.date = ''
      this.formData.time = ''
      this.formData.totalTime = this.totalTime
    },

    async findService () {
      const [res, err] = await FindService({
        shopId: this.shop,
        id: this.formData.service.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.formData.service = res
    },

    reset () {
      this.availableDate = []
      this.formData = {
        otherService: false,
        service: null,
        attachServices: null,
        serviceUnit: null,
        totalTime: 0,
        isOverTime: false,
        date: '',
        time: '',
      }
    },

  },
}
</script>

<style scoped lang="postcss">
</style>

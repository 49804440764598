import { admin } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentScheduleBan`
}

//= > 取得時段
export const GetPeriods = async ({
  shopId,
  appointmentUnitId,
  start,
  end,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/times`,
    params: {
      appointmentUnitId,
      start,
      end,
    },
  })

  return res.data
}

//= > 更新時段
export const UpdatePeriod = async ({
  shopId,
  appointmentUnitId,
  times,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/times`,
    data: {
      appointmentUnitId,
      times,
    },
  })

  return res.data
}

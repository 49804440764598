<template>
  <div class="page-title">
    <div class="flex items-center title">
      <span v-if="icon" class="material-icons cursor-pointer" style="margin-right: 9px;" @click="$emit('iconClick')">{{ icon }}</span>
      <span class="title" :style="`font-size: ${fontSize}px`">{{ title }}</span>
    </div>
    <div>
      <el-button
        v-if="showBtn4"
        class="btn page-title-btn"
        plain
        :data-cy="cyBtn4"
        @click="$emit('btn4Click')"
      >
        {{ btn4 }}
      </el-button>
      <el-button
        v-if="showBtn3"
        class="btn page-title-btn"
        plain
        :data-cy="cyBtn3"
        @click="$emit('btn3Click')"
      >
        {{ btn3 }}
      </el-button>
      <el-button
        v-if="showBtn2"
        class="btn page-title-btn"
        :plain="showBtn? true : false"
        :type="showBtn? '' : 'primary'"
        :data-cy="cyBtn2"
        @click="$emit('btn2Click')"
      >
        {{ btn2 }}
      </el-button>
      <el-button
        v-if="showBtn"
        class="btn page-title-btn"
        type="primary"
        :data-cy="cyBtn1"
        @click="$emit('btnClick')"
      >
        {{ btn || '編輯' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkFeature } from '@/store/modules/permission'

export default {
  name: 'PageTitle',
  props: {
    title: { default: '' },
    cyBtn1: { default: '' },
    cyBtn2: { default: '' },
    cyBtn3: { default: '' },
    cyBtn4: { default: '' },

    btn: { default: '' },
    btn2: { default: '' },
    btn3: { default: '' },
    btn4: { default: '' },

    btnFeature: { default: '' },
    btn2Feature: { default: '' },
    btn3Feature: { default: '' },
    btn4Feature: { default: '' },

    fontSize: { default: 18 },
    hideBtn: Boolean,
    icon: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'userFeatures',
    ]),
    btnPermission () {
      if (!this.btnFeature) return true
      return checkFeature(this.userFeatures, this.btnFeature)
    },
    btn2Permission () {
      if (!this.btn2Feature) return true
      return checkFeature(this.userFeatures, this.btn2Feature)
    },
    btn3Permission () {
      if (!this.btn3Feature) return true
      return checkFeature(this.userFeatures, this.btn3Feature)
    },
    btn4Permission () {
      if (!this.btn4Feature) return true
      return checkFeature(this.userFeatures, this.btn4Feature)
    },

    showBtn () {
      return !this.hideBtn && this.btnPermission
    },
    showBtn2 () {
      return !this.hideBtn && this.btn2 && this.btn2Permission
    },
    showBtn3 () {
      return !this.hideBtn && this.btn3 && this.btn3Permission
    },
    showBtn4 () {
      return !this.hideBtn && this.btn3 && this.btn4Permission
    },
  },
}
</script>

<style lang="postcss" scoped>
.page-title {
  @apply flex justify-between mb-[24px];
}

.title {
  @apply font-medium;
}

.btn {
  @apply min-w-[100px] text-normal;
}
</style>

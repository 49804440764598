<template>
  <div class="other-user">
    <el-form label-position="top">
      <div v-for="(user, key, index) in formData" :key="key">
        <el-form-item :label="`其他服務對象姓名 -${index + 1}`">
          <div>
            <el-input v-model="formData[key].name" />
            <el-button
              class="delete-btn ml-[12px]"
              type="text"
              @click="removeUser(key)"
            >
              刪除
            </el-button>
          </div>
        </el-form-item>
        <el-form-item :label="`其他服務對象手機號碼 -${index + 1}`">
          <el-input v-model="formData[key].phone" />
        </el-form-item>
      </div>
    </el-form>

    <div v-if="count < peopleCount - 1" class="add-user-btn" @click="addUser">
      <div>新增其他服務對象資料</div>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash'

export default {
  name: 'BlockOtherUser',
  props: {
    peopleCount: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    count: 0,
    formData: {},
  }),
  computed: {
    compactData () {
      return map(this.formData, (user) => ({
        name: user.name,
        phone: user.phone,
      }))
    },
  },
  watch: {
    formData: {
      handler () {
        this.$emit('update', this.compactData)
      },
      deep: true,
    },
    peopleCount () {
      if (this.count > this.peopleCount - 1) {
        this.count = this.peopleCount - 1
        this.refreshFormData()
      }
    },
  },
  methods: {
    addUser () {
      this.count++
      this.refreshFormData()
    },

    removeUser (key) {
      this.$delete(this.formData, key)
      this.count--
      this.refreshFormData()
    },

    refreshFormData () {
      const cloneForm = JSON.parse(JSON.stringify(this.formData))
      const keys = Object.keys(cloneForm)
      this.formData = {}
      for (let index = 0; index < this.count; index++) {
        const exist = keys[index]
        if (exist) { this.$set(this.formData, `user_${index + 1}`, cloneForm[keys[index]]) } else {
          this.$set(this.formData, `user_${this.count}`, {
            name: '',
            phone: '',
          })
        }
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.delete-btn {
  @apply underline text-normal font-normal;
  @apply text-danger;
}

.add-user-btn {
  @apply flex items-center text-primary-100 cursor-pointer mb-[16px];
}
</style>
